import { graphql } from "gatsby"
import * as React from "react"
import tw from "twin.macro"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const TermsOfUsePage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Terms of Use" />
      <div css={[tw`container mx-auto py-16 md:py-32 px-4`]}>
        <div
          css={[tw`prose lg:prose-xl mx-auto`]}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    markdownRemark(frontmatter: { slug: { eq: "terms" } }) {
      html
    }
  }
`

export default TermsOfUsePage
